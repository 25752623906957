import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import vehicles from '../assets/images/vehicles_service.jpg'
import licenses from '../assets/images/licenses_service.jpg'
import signals from '../assets/images/signals.jpg'
import printer from '../assets/images/printer.jpg'

const Services = (props) => (
    <Layout>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Tramitología</h2>
                    </header>
                    <p>Brindamos variedad de servicios para realizar todos los trámites ante las secretarías de tránsito relacionados con vehículos, licencias de conducción, inscripciones al RUNT, exámenes médicos,
                        asesorías legales, cursos de automovilismo, papelería y mucho más.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/vehicles" className="image">
                        <img className="lazyload" data-src={vehicles} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Trámites para vehículos</h3>
                            </header>
                            <p>En esta sección encontrará lo que necesita saber sobre trámites relacionados directamente con los vehículos: Matrícula inicial, traspaso, cambio de motor, cambio de color, registro de cuenta, regrabación de motor, regrabación de chasis, duplicado de licencia de tránsito, etc.</p>
                            <ul className="actions">
                                <li><Link to="/vehicles" className="button">Ver más</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/licenses" className="image">
                        <img className="lazyload" data-src={licenses} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Trámites para licencias</h3>
                            </header>
                            <p>En esta sección encontrará lo que necesita saber sobre trámites relacionados directamente con los propietarios de los vehículos: Expedición de licencias de conducción, refrendación, duplicados, cambio de documento y recategorización hacia arriba y hacia abajo.</p>
                            <ul className="actions">
                                <li><Link to="/licenses" className="button">Ver más</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/external_services" className="image">
                        <img className="lazyload" data-src={signals} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Enlaces externos</h3>
                            </header>
                            <p>En la mayoría de procesos es necesario realizar conexiones con otras instituciones que permitan la consecución de algún trámite en cuestión, para ello, en esta sección encontrará sobre nuestros enlaces con diferentes instituciones que facilitan dichos procesos.</p>
                            <ul className="actions">
                                <li><Link to="/external_links" className="button">Ver más</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/other_services" className="image">
                        <img className="lazyload" data-src={printer} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Otros servicios</h3>
                            </header>
                            <p>En esta sección encontrará otros servicios que facilitan la documentación necesaria para realizar los trámites, entre ellos se encuentran: Fotocopias, escáner, laminación, impresiones, papelería, recibos del RUNT, corresponsal bancario, etc.</p>
                            <ul className="actions">
                                <li><Link to="/other_services" className="button">Ver más</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Services